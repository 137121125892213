import React from "react";
import * as styles from "./Contact.module.scss";
import FormSection from "./Form/FormSection";

const ContactComponent = ({ showDetails }) => {
  return (
    <section className={styles.contact_container}>
      <FormSection showDetails={showDetails} />

      <section className={styles.map_container}>
        <iframe
          title="myfFrame"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2512.59840394827!2d16.348125415750182!3d50.96812887955015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fa976dc4a9f39%3A0xaf22f7efa71c63df!2sRestauracja%20Stragona!5e0!3m2!1spl!2spl!4v1640283318883!5m2!1spl!2spl"
          width="100%"
          height="550"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </section>
    </section>
  );
};

export default ContactComponent;
