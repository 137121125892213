import React from "react";
import styled from "styled-components";
import PokojeIMG from "../../assets/pokoje.jpg";
import Photo1 from "../../assets/pokoje/15.jpg";
import Photo2 from "../../assets/pokoje/21.jpg";
import Photo3 from "../../assets/pokoje/22.jpg";
import Fade from "react-reveal/Fade";
import PriceSection from "../CennikComponents/PriceSection";
import PokojeSection from "../PokojeComponents/PokojeSection";
import { AiOutlinePhone } from "react-icons/ai";
import { StaticImage } from "gatsby-plugin-image";
import { HiOutlinePhotograph } from "react-icons/hi";
import { Fade as FadeSlide } from "react-slideshow-image";
import { Link } from "gatsby";
import Slider from "react-slick";
import Contact from "../KontaktComponents/ContactComponent";
const MainSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Wrapper>
        <Header>
          <h1 className="text">Przyjecia</h1>
        </Header>
        <Fade>
          <div className="container">
            <div className="box_container-left">
              <h3>PRZYJĘCIA</h3>
              <p>
                Posiadamy wieloletnie doświadczenie w organizacji przyjęć
                weselnych, chrzcin, komunii, styp, przyjęć okolicznościowych,
                biesiad, spotkań biznesowych, imprez integracyjnych oraz szkoleń
                i konferencji. Do Państwa dyspozycji posiadamy cztery sale:
              </p>
              <ul>
                <li>salę balowa do 100 osób</li>
                <li>salę restauracyjną do 60 osób</li>
                <li>salę konferencyjną do 45 osób</li>
                <li>kameralną salę kominkową do 25 osób </li>
                <li>
                  oraz altanę grillową, która pomieści do 70 osób (dostępna
                  jedynie w okresie letnim).
                </li>
              </ul>
              <p>
                Serdecznie zapraszamy do kontaktu - chętnie pomożemy w wyborze
                menu, stworzymy wyjątkową atmosferę, a nasz dekorator zadba o
                bajeczny wystrój sali.
              </p>
              <div className="photos">
                <h3>Altana</h3>
                <StaticImage
                  src="../../assets/przyjecia/jpg/altana1.jpg"
                  alt="przyjęcia"
                />
                <StaticImage
                  src="../../assets/przyjecia/jpg/altana2.jpg"
                  alt="przyjęcia"
                />
                <StaticImage
                  src="../../assets/przyjecia/jpg/altana3.jpg"
                  alt="przyjęcia"
                />
                <h3>Restauracja</h3>
                <StaticImage
                  src="../../assets/przyjecia/jpg/restauracja.jpg"
                  alt="przyjęcia"
                />
                <StaticImage
                  src="../../assets/przyjecia/jpg/restauracja2.jpg"
                  alt="przyjęcia"
                />
                <StaticImage
                  src="../../assets/przyjecia/jpg/restauracja3.jpg"
                  alt="przyjęcia"
                />
                <h3>Sala balowa</h3>
                <StaticImage
                  src="../../assets/przyjecia/jpg/salabalowa1.jpg"
                  alt="przyjęcia"
                />
                <StaticImage
                  src="../../assets/przyjecia/jpg/salabalowa2.jpg"
                  alt="przyjęcia"
                />
                <StaticImage
                  src="../../assets/przyjecia/jpg/salabalowa3.jpg"
                  alt="przyjęcia"
                />
                <h3>Sala kominkowa</h3>
                <StaticImage
                  src="../../assets/przyjecia/jpg/salakominkowa1.jpg"
                  alt="przyjęcia"
                />
                <StaticImage
                  src="../../assets/przyjecia/jpg/salakominkowa2.jpg"
                  alt="przyjęcia"
                />
                <StaticImage
                  src="../../assets/przyjecia/jpg/salakominkowa3.jpg"
                  alt="przyjęcia"
                />
              </div>
            </div>
            <div className="box_container-right">
              <h3 className="info">
                Skorzystaj z formularza kontaktowego i zapytaj o wolne terminy
              </h3>
              <Contact />
            </div>
          </div>
        </Fade>
        <section className="menu_container_all">
          <div className="menu_container">
            <div className="slide-container">
              <StaticImage
                src="../../assets/menu/nowe/menu_obiad.jpg"
                alt="menu"
              />
            </div>
          </div>
          <div className="menu_container">
            <div className="slide-container">
              <FadeSlide autoplay={false}>
                <div className="each-FadeSlide">
                  <div>
                    <StaticImage
                      src="../../assets/menu/nowe/m_okol110_1.jpg"
                      alt="menu"
                    />
                  </div>
                </div>
                <div className="each-FadeSlide">
                  <div>
                    <StaticImage
                      src="../../assets/menu/nowe/m_okol110_2.jpg"
                      alt="menu"
                    />
                  </div>
                </div>
              </FadeSlide>
            </div>
          </div>
          <div className="menu_container">
            <div className="slide-container">
              <FadeSlide autoplay={false}>
                <div className="each-FadeSlide">
                  <div>
                    <StaticImage
                      src="../../assets/menu/nowe/m_okol135_1.jpg"
                      alt="menu"
                    />
                  </div>
                </div>
                <div className="each-FadeSlide">
                  <div>
                    <StaticImage
                      src="../../assets/menu/nowe/m_okol135_2.jpg"
                      alt="menu"
                    />
                  </div>
                </div>
              </FadeSlide>
            </div>
          </div>
          <div className="menu_container">
            <div className="slide-container">
              <FadeSlide autoplay={false}>
                <div className="each-FadeSlide">
                  <div>
                    <StaticImage
                      src="../../assets/menu/nowe/m_weselne1.jpg"
                      alt="menu"
                    />
                  </div>
                </div>
                <div className="each-FadeSlide">
                  <div>
                    <StaticImage
                      src="../../assets/menu/nowe/m_weselne2.jpg"
                      alt="menu"
                    />
                  </div>
                </div>
                <div className="each-FadeSlide">
                  <div>
                    <StaticImage
                      src="../../assets/menu/nowe/m_weselne3.jpg"
                      alt="menu"
                    />
                  </div>
                </div>
                <div className="each-FadeSlide">
                  <div>
                    <StaticImage
                      src="../../assets/menu/nowe/m_weselne4.jpg"
                      alt="menu"
                    />
                  </div>
                </div>
              </FadeSlide>
            </div>
          </div>
        </section>
      </Wrapper>
    </>
  );
};

const CustomButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0 2rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border: 1px solid rgba(34, 44, 43, 0.15);
  color: #384140;
  background: transparent;
  cursor: pointer;
  transition: color 0.3s ease, border 0.3s ease, background-color 0.3s ease;
  margin-top: 50px;
  &:hover {
    color: whitesmoke;
    background-color: #384140;
  }
`;

const StickyBar = styled.div`
  width: 100vw;
  height: 60px;
  z-index: 12;
  box-shadow: 0px 1px 0px 0px rgb(34 44 43 / 15%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 61px;
  left: 0;
  background-color: #fff;

  p {
    text-transform: uppercase;
    font-size: 13px;
    color: #919695;
    margin: 10px;
    &.contact {
      color: #009500;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #919695;
      &.contact {
        color: #009500;
      }
      svg {
        margin: 3px;
      }
    }
    cursor: pointer;
  }
`;

const Wrapper = styled.section`
  .menu_container {
    width: 80vw;
    max-width: 640px;
  }
  ul {
    li {
      margin-top: 15px;
      color: #495453;
      line-height: 1.75;
      font-weight: 200;
    }
  }
  width: 100vw;
  min-height: 90vh;
  .photos {
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 280px;
      margin: 10px;
    }
  }
  h3 {
    color: #495453;
    &.info {
      @media (max-width: 840px) {
        text-align: center;
        width: 100vw;
        display: flex;
        justify-content: center;
      }
    }
  }
  p {
    color: #495453;
    line-height: 1.75;
    font-weight: 200;
  }
  .container {
    min-height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
  .box_container-left {
    padding: 35px;
    width: 60vw;
    align-self: flex-start;
    ul {
      @media (max-width: 1024px) {
        padding: 0 50px;
      }
    }
    p {
      @media (max-width: 1024px) {
        padding: 30px;
      }
    }
    .menu_container {
      &.padding {
        @media (min-width: 1056px) {
          padding-top: 302px;
        }
      }
    }
    @media (max-width: 667px) {
      align-self: center;
      justify-self: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    @media (max-width: 1024px) {
      // padding: 10px;
      margin-top: 20px;
      padding: 0;

      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .box_container-right {
    align-self: flex-start;
    // padding: 35px;
    margin-top: 35px;
    width: 40vw;
    @media (max-width: 1024px) {
      width: 100vw;
    }
  }
  .menu_container_all {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    .menu_container {
      @media (max-width: 1024px) {
        width: 95vw;
      }
      width: 40vw;
      margin: 16px;
      border: 1px solid gray;
    }
  }
`;

const Header = styled.div`
  height: 55vh;
  background-image: url(${PokojeIMG});
  background-position: center;
  position: relative;
  background-size: cover;

  .text {
    width: 440px;
    position: absolute;
    bottom: 5vh;
    text-align: center;
    font-family: "Josefin Slab", serif;
    font-weight: 200;
    font-size: 80px;
    left: calc(50% - 220px);
    color: white;
    @media (max-width: 1024px) {
      font-size: 46px;
      width: 320px;
      left: calc(50% - 160px);
    }
  }
`;

export default MainSection;
