import React from "react";
import * as styles from "./FormSection.module.scss";
import Form from "./Form";
import { FaMapMarkerAlt } from "react-icons/fa";
import { AiTwotonePhone } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
const FormSection = ({ modeRedux, showDetails }) => (
  <section
    className={`${styles.contact_form} ${modeRedux && styles.dark_mode}`}
  >
    {showDetails ? (
      <div className={styles.contact_items_container}>
        <div className={styles.contact_item}>
          <div className={styles.contact_item_icon}>
            <FaMapMarkerAlt />
          </div>
          <div className="item_description">
            <h3 className={`${styles.title} ${modeRedux && styles.dark_mode}`}>
              Adres:
            </h3>
            <p
              className={`${styles.sub_title} ${modeRedux && styles.dark_mode}`}
            >
              58-150 Strzegom,
              <br />
              Al. Wojska Polskiego 47
            </p>
          </div>
        </div>
        <div className={styles.contact_item}>
          <div className={styles.contact_item_icon}>
            <AiTwotonePhone />
          </div>
          <div className="item_description">
            <h3 className={`${styles.title} ${modeRedux && styles.dark_mode}`}>
              Numer telefonu:
            </h3>
            <p
              className={`${styles.sub_title} ${modeRedux && styles.dark_mode}`}
            >
              <a href="tel:663-776-493">663-776-493</a>
            </p>
          </div>
        </div>
        <div className={styles.contact_item}>
          <div className={styles.contact_item_icon}>
            <AiOutlineMail />
          </div>
          <div className="item_description">
            <h3 className={`${styles.title} ${modeRedux && styles.dark_mode}`}>
              Email:
            </h3>
            <a
              href="mailto:stragona@email.com"
              title="Email"
              className={`${styles.sub_title} ${modeRedux && styles.dark_mode}`}
            >
              biuro@stragona.com
            </a>
          </div>
        </div>
      </div>
    ) : null}

    <Form modeRedux={modeRedux} />
  </section>
);
export default FormSection;
